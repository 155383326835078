import { defineStore } from 'pinia';
import { reactive} from 'vue';

//pinia
export const menuStore=reactive({
        menujson:<any>null,btn_list_json:<any>null
}
);
export const delmenuStore=()=>{
  menuStore.menujson=null;menuStore.btn_list_json=null;
};
export const btnCheck=(str:any)=>{
  if(JSON.parse(menuStore.btn_list_json)){
    return JSON.parse(menuStore.btn_list_json).includes(str);
  }else{
    return false;
  }
    
}
