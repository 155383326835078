import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import { loginoutAction, request } from '../request/index';
import { ElMessage } from "element-plus";

/*pinia状态管理，菜单管理*/
import {menuStore}  from '@/store/index';
//import { storeToRefs } from 'pinia';
//const store = menuStore();
//const {menujson,btn_list_json}=storeToRefs(store);

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    children:[
      {
        path: "/main",
        name: "main",
        component: () =>
          import("../components/MainList.vue"),
      },
      {
        path: "/user",
        name: "user",
        component: () =>
          import("../components/user/UserList.vue"),
      },
      {
        path: "/role",
        name: "role",
        component: () =>
          import("../components/user/RoleList.vue"),
      },
      {
        path: "/menu",
        name: "menu",
        component: () =>
          import("../components/user/MenuList.vue"),
      },
      {
        path: "/group",
        name: "group",
        component: () =>
          import("../components/user/GroupList.vue"),
      },
      {
        path: "/client",
        name: "client",
        component: () =>
          import("../components/client/ClientList.vue"),
      },
    ]
  },
  {
    path: "/login",
    name: "login",
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/404',
    name: 'notfount',
    component: () => import(/* webpackChunkName: "404" */ '../views/Error404View.vue')
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/404'
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach((to, from, next) => {

  const token = localStorage.getItem("token");
  if(!token){
    if(to.name=='login'||to.name=='reset'){
      //console.log("没有token，但是是login页面，直接往下走");
      next();
    }else{
      //console.log("没有token，但是不是login页面，跳转登陆页面");
      next({ name: "login" });
    }
    //如果是去login,直接跳转，如果是其他页面跳login
  }else{
    //有token，如果是login，直接跳转，如果不是login，检查token是否有效
    if(to.name=='login'||to.name=='reset'){
      //console.log("有token，但是是login页面，直接往下走");
      next();
    }else{
      //const menu=sessionStorage.getItem('menu');
      const refreshSessionStorage={flag:true};
      // if(menu){
      //   refreshSessionStorage.flag=false;
      // }else{
      //   refreshSessionStorage.flag=true;
      // }
      request('/login/checkToken', refreshSessionStorage, 'post').then((res: any) => {
        //token检验，不可用就清除localStorage,跳转登陆页面
        console.log(res.data);
        console.log('检测菜单，checktoken');
        if(res.data.ref==1){
          localStorage.setItem("token",res.data.token);
        }
        if (res.data.code == 1) {
          if(refreshSessionStorage.flag){
            if(res.data.menu){
              //sessionStorage.setItem("menu",JSON.stringify(res.data.menu));
              menuStore.menujson=JSON.stringify(res.data.menu);
              menuStore.btn_list_json=JSON.stringify(res.data.btn_list);
            }else{
              ElMessage({ message: 'This account has not been assigned any permissions! Please contact the administrator!',type: 'error',  });
            }
          }
          
          //console.log("进Token验证了！，token有效");
          //拉取权限列表存进sessionStorage
          next();
        }else{
           //console.log("进Token验证了！,且验证失败，跳转登录页");
          loginoutAction();
          next({ name: "login" });
        }
      });
    }
  }
    

});
export default router;
