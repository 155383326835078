import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from 'pinia';
import 'element-plus/es/components/message-box/style/index'//message-box样式自动导入出错，现在临时的解决办法
import 'element-plus/es/components/message/style/index'//message样式,自动导入出错，现在临时的解决办法
import 'element-plus/es/components/tree/style/index'//message样式,自动导入出错，现在临时的解决办法
// import ElementPlus from "element-plus";
// import "element-plus/dist/index.css";
import * as ElIcons from '@element-plus/icons-vue'
 
const pinia = createPinia();
const app = createApp(App)
for (const name in ElIcons){
     app.component(name,(ElIcons as any)[name])
}
app.use(pinia).use(router).mount("#app");

