import axios from 'axios';
import { ElMessage } from 'element-plus'  // 引入el 提示框，这个项目里用什么组件库这里引什么
const instance = axios.create({
    baseURL: 'http://localhost/payment_collection_api/public/admin',
    timeout: 50000,
    headers: {
        'Content-Type':'application/json;charset=UTF-8'
    }
  });
  // const token = window.localStorage.getItem('token');
  // if(token){
  //   instance.defaults.headers.common['Authorization'] = token;
  // }
 
// 添加请求拦截器
// instance.interceptors.request.use(function (config) {
//     // 在发送请求之前做些什么
//     return config;
//   }, function (error) {
//     // 对请求错误做些什么
//     return Promise.reject(error);
//   });

instance.interceptors.request.use(
  config=>{
    if(localStorage.getItem('token')){
      const token=localStorage.getItem('token');
      config.headers.TOKEN="Bearer "+token;
      //={
        //...config.headers,
        //Authorization:"Bearer "+token
      //};
    }
    //console.log('config',config);
    return config;
  },
  err=>{
    return Promise.reject(err);
  }
);
// 添加响应拦截器
instance.interceptors.response.use(
    response => {
       console.log(response);
       if(response.data.code===10001){
        ElMessage({ message: '抱歉!没有权限！', type: 'error' });
      }
      if(response.data.code===20000){
        ElMessage({ message: 'Token verification failed! Please log in again!', type: 'error' });
        //loginoutAction();
        //window.location.href = "/login"; 
      }
      if(response.data.code===30000){//
        //loginoutAction();
        //window.location.href = "/login"; 
      }
      return response;
    },
    error => {
      const {response} = error;  
      if (response) {
        // 请求已发出，但是不在2xx的范围
        return Promise.reject(response.data);
      } else {
        ElMessage.warning('Network connection is abnormal. Please try again later!');
      }
    }
  );
// 封装 GET POST 请求并导出
/*pinia状态管理，菜单管理*/
import {delmenuStore}  from '@/store/index';
export function loginoutAction(){//登陆失效，退出登陆清空处理
  localStorage.removeItem("token");
  localStorage.removeItem("name");
  sessionStorage.removeItem('info');
  localStorage.removeItem("account");
  delmenuStore();//删除menu btnlist
}

export function request(url='',params={},type='POST'){
    //设置 url params type 的默认值
    return new Promise((resolve,reject)=>{
      let promise
      if( type.toUpperCase()==='GET' ){
        promise = instance({
          url,
          params
        })
      }else if( type.toUpperCase()=== 'POST' ){
        promise = instance({
          method:'POST',
          url,
          data:params,
          
        })
      }
      //处理返回
      if(promise){
        promise.then(res=>{
            resolve(res)
          }).catch(err=>{
            reject(err)
          })
      }
      
    })
    }
  //export default instance;